import React from 'react';
import Header from "./Header";
import Section9 from "./Section9";
import Section10 from "./Section10";
import ScrollToTop from "./ScrollToTop";

const Privacy = () => {
    return (
        <div>
            <ScrollToTop />
            <Header />
            <div className="w-[100%]">
                <div className="relative w-full">
                    <img src="./images/contact_back.jpg" alt="hing" className="w-full h-[400px]" />
                    <h1 className="text-red font-bold absolute bg-white_blur top-[55%] w-full text-center uppercase max-sm:text-3xl max-sm:top-[57%]">Privacy Policy</h1>
                </div>
                <div className="bg-red p-8 shadow-md text-white">

                    <p className="text-xl py-6 max-sm:text-base">
                        India's Top Leading Provider In The Hing Import Business "SRI RAGHUNATH JI OVERSEAS" ("we," "our," or "the Company") values your privacy and is committed to protecting any personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our services or interact with our website.
                    </p>

                    <div class="container">

                        <div className="mt-8">
                            <h2 className="text-2xl">Information Collection</h2>

                            <p className="text-xl py-6 max-sm:text-base">
                                <strong>Personal Information:</strong> When you engage with our services, we may collect personal information such as your name, contact details, address, and payment information for order processing and delivery purposes.
                            </p>

                            <p className="text-xl py-6 max-sm:text-base">
                                <strong>Usage Information:</strong> We may collect information about how you interact with our website, including IP addresses, browser types, device identifiers, pages visited, and the duration of your visits. This data helps us improve our services and user experience.
                            </p>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-2xl">Use of Information</h2>

                            <p className="text-xl py-6 max-sm:text-base">
                                We use the collected information for the following purposes:
                            </p>

                            <ul className="list-disc pl-8">
                                <li>To process orders and provide our products and services to you.</li>
                                <li>To communicate with you regarding your orders, inquiries, or updates.</li>
                                <li>To improve our services, website functionality, and customer experience.</li>
                                <li>To comply with legal and regulatory requirements.</li>
                            </ul>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-2xl">Information Sharing</h2>

                            <p className="text-xl py-6 max-sm:text-base">
                                We may share your information in the following circumstances:
                            </p>

                            <ul className="list-disc pl-8">
                                <li>With third-party service providers who assist us in operating our business and providing services to you.</li>
                                <li>To comply with legal obligations, respond to lawful requests, or protect our rights and interests.</li>
                                <li>In connection with a corporate transaction, such as a merger, acquisition, or sale of assets.</li>
                            </ul>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-2xl">Data Security</h2>

                            <p className="text-xl py-6 max-sm:text-base">
                                We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                            </p>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-2xl">Your Choices</h2>

                            <p className="text-xl py-6 max-sm:text-base">
                                You have the right to access, correct, or delete your personal information. You can also opt out of receiving marketing communications from us.
                            </p>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-2xl">Contact Us</h2>

                            <p className="text-xl py-6 max-sm:text-base">
                                If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at <a href="mailto:contact@sriraghunathjioverseas.com">contact@sriraghunathjioverseas.com</a>.
                            </p>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-2xl">Changes to this Policy</h2>

                            <p className="text-xl py-6 max-sm:text-base">
                                We reserve the right to update or modify this Privacy Policy at any time. We encourage you to review this page periodically for any changes.

                                <br>
                                </br>
                                By using our services or interacting with our website, you agree to the terms of this Privacy Policy.
                            </p>


                        </div>

                    </div>
                </div>
            </div>

            <Section9 />
            <Section10 />
        </div>
    )
}

export default Privacy;
