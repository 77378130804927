
import { useState } from "react";

const Contactus =() => {
    const [formState, setFormState] = useState({});

    const changeHandler = (event) => {
      setFormState({ ...formState, [event.target.name]: event.target.value });
    };

    const submitHanlder = (event) => {
        event.preventDefault();
        let name = document.getElementById("name").value;
        let last = document.getElementById("last").value;
        let contact = document.getElementById("contact").value;
        let email = document.getElementById("email").value;
        let message = document.getElementById("message").value;
        let body ="Name" + name + "<br/> last:"+last+  "<br/> contact number:" + contact +"<br/> Email:"+ email + "<br/> message:"+ message;

        const config = {
            SecureToken : "df9d3e15-bcfd-448a-934b-2bcb41fbbfcd" ,
            To : "sriraghunathjioverseas1931@gmail.com",
            From : "sriraghunathjioverseas1931@gmail.com" ,
            Subject : "This is the subject",
            Body : body,
        };
        if(window.Email){
            window.Email.send(config).then(() => alert("email sent succesfully"));
        }
    }

    
  return(
    <div className="w-3/5 max-sm:w-full" data-aos="fade-up">
        <h3 className="capitalize text-white mb-6 max-sm:text-center">contact form</h3>
        <form className =  " "onSubmit={submitHanlder}>
            <input type="text" placeholder="First Name" id="name" onChange={changeHandler} name="Fname"  className="w-[49%]  mr-[2%] mb-6 p-3 rounded-xl max-sm:w-full" required />
            <input type="text" placeholder="Last Name" id="last" onChange={changeHandler} name="Lname"  className="w-[49%] p-3 mb-6 rounded-xl  max-sm:w-full" required  /><br/>
            <input type="tel" placeholder="Contact Number"  id="contact" onChange={changeHandler} name="contact" title="10 digit number only" pattern="[1-9]{1}[0-9]{9}" className="w-full  rounded-xl p-3 mb-6" required  /><br/>
            <input type="email" placeholder="Email" id="email" onChange={changeHandler} name="email" className="w-full rounded-xl  p-3 mb-6" required  /><br/>
            <textarea name="comment" rows="8" cols="50" placeholder="Message" id="message" className="w-full rounded-xl p-3 mb-6"></textarea>
            <input type="submit" value="Submit"  onChange={changeHandler} className=" w-fit text-white text-base font-bold  border-2 border-solid border-yellow rounded-none text-center capitalize px-6 py-2 hover:bg-yellow hover:text-black rounded-xl cursor-pointer max-sm:mt-0 max-sm:px-4 max-sm:py-2 max-sm:text-sm max-sm:ml-[15%]" />
        </form>
    </div>
  )
    
};

export default Contactus;
