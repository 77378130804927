import Header from "./Header";
import Section9 from "./Section9";
import Section10 from "./Section10";
import ScrollToTop from "./ScrollToTop";
//import 'video-react/dist/video-react.css';
//import { Player } from "video-react";
import video from "../videos/video.mp4";
import Afgani from "../videos/Afgani.mp4";
import Afgani2 from "../videos/Afgani2.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Gallery= () =>{
    useEffect(() => {
        AOS.init({ 
            duration: 2500,

            once:true,
        });
        AOS.refresh();
    }, []);
    const data = [
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image0.jpeg',
        },
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image1.jpeg',
        },
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image2.jpeg',
        },
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image3.jpeg',
        },
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image4.jpeg',
        },
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image5.jpeg',
        },
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image0.jpeg',
        },
        {
            value : 'HYBRID NUKRA' ,
            img : './images/image1.jpeg',
        },
    ]

    var settings = {
        dots: true,
        infinite: true,
        arrows:false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false, 
        pauseOnHover: true,
        cssEase: 'linear',
        waitForAnimate: false,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    }

    return (
        <div className="bg-red">
            <ScrollToTop />
            <Header/>
            <div className="relative w-full">
                <img src="./images/gallery.jpg" alt="hing" className="w-full h-[400px] max-sm:h-[200px]" />
                <h1 className="text-red font-bold absolute bg-white_blur top-[55%] w-full text-center uppercase max-sm:text-3xl max-sm:top-[57%]">Gallery</h1>
            </div> 
           {/* <div className="bg-red text-white px-[6%]" >
                <div className="flex  max-sm:flex-col-reverse py-[4%] max-sm:py-[10%] " >
                    <div className="w-1/2  flex flex-col  max-sm:w-full" data-aos="flip-up" >
                        <h1 className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">ASFOETIDA PLANT GROWTH</h1>
                        <p className="text-2xl  max-sm:text-base max-sm:pr-0 max-sm:text-center" data-aos="flip-up" >A fully frown asafoetida plant,<br></br> before the harvest, during the spring season,<br></br> just before the harvest begins 
                        </p>
                    </div>
                    <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center">
                       <img src="./images/plant1.jpeg" alt="spices" className="w-2/5 shadow-[20px_10px_0px_0px_rgba(300,300,80)] rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-6" data-aos="zoom-in" />
                    </div>
    </div>yee hai 1st.....................*/}
                {/* <div className="flex flex-col py-[2%] max-sm:pt-[6%] max-sm:text-center"  >
                    <h1 className="uppercase text-[40px] text-yellow pr-[50%] leading-tight  max-sm:px-0 max-sm:text-2xl" data-aos="flip-up">IDENTIFYING THE PLANT</h1>
                    <p className="text-xl mb-6 pr-[20%] max-sm:text-base max-sm:pr-0" data-aos="flip-up" >During April and May, asafoetida plants emerge, their roots containing hing. Recognition is key; spotting its distinct appearance aids farmers in harvesting before May's stems fall. The plant's image displays recognizable leaves atop a mountain. <br></br><br></br>
                        A. Identifying Asafoetida
                        In spring, recognizing asafoetida amidst nature's backdrop initiates the harvesting. <br></br>
                        B. Delicate Root Digging
                        Preserving the hing-rich root demands careful excavation using traditional techniques.</p>
                </div> */}
                {/*......................................<div className="flex pb-[4%] w-[100%] max-sm:flex-col max-sm:pb-[10%]">
                    <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center"  >
                    
                        <img src="./images/plant2.jpeg" alt="spices" className="w-[40%] rounded-xl shadow-[20px_10px_0px_0px_rgba(300,300,80)] max-sm:mb-6" data-aos="zoom-in" />
                    </div>
                    <div className="w-1/2  flex flex-col  max-sm:w-full" >
                        
                        <p className="text-2xl mb-6 text-right max-sm:text-base max-sm:text-center" data-aos="flip-up">Hing Liquid Coming out from asafoetida,<br></br> ready for collection</p>
                    </div>
                </div>
                <div className="flex pb-[4%] max-sm:flex-col-reverse max-sm:items-center max-sm:text-center max-sm:pb-[10%]"  >
                    <div className="w-1/2  flex flex-col  max-sm:w-full" >
                     
                        <p className="text-2xl  max-sm:text-base max-sm:pr-0" data-aos="flip-up">Hing Liquid Is coming out of the plant.<br></br> Farmer is ready to extract it out,<br></br> with the help of a knife</p>
                    </div>
                    <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center"  >
                        <img src="./images/plant3.jpeg" alt="spices" className="w-[40%] shadow-[20px_10px_0px_0px_rgba(300,300,80)] rounded-xl max-sm:ml-0 max-sm:w-[40%] max-sm:mb-6" data-aos="zoom-in" />
                    </div>
                </div>
                <div className="flex pb-[4%] w-[100%] max-sm:flex-col max-sm:pb-[10%]">
                    <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center"  >
                        <img src="./images/plant4.jpeg" alt="spices" className="w-[40%] shadow-[20px_10px_0px_0px_rgba(300,300,80)] rounded-xl  max-sm:mb-6" data-aos="zoom-in" />
                    </div>
                    <div className="w-1/2  flex flex-col  max-sm:w-full" >
                       
                        <p className="text-2xl  text-right max-sm:text-base max-sm:text-center max-sm:pl-0" data-aos="flip-up"> Naturalky dried asafoetida ,<br></br> ready for extraction from the root of Asafoetida plant.</p>                    
                    </div>
            </div>.............................*/}
                {/* <div className="text-center"  >
                    <h1 className="uppercase text-[40px] text-right text-yellow  leading-tight pb-4 pl-[50%]  max-sm:px-0 max-sm:text-2xl max-sm:text-center max-sm:pl-0" data-aos="flip-up">STORING AND PACKING FOR FURTHER EXPORT</h1>
                    <p className="text-xl pb-6 pl-[20%] text-right max-sm:text-base max-sm:text-center max-sm:pl-0" data-aos="flip-up"> By September-end, farmers bring their produce down from the mountains to their homes. From there, it reaches the exporter's godown, where it is checked and repacked in export-ready cans for being shipped to India.
                        A. The Final Harvest
                        The asafoetida is harvested and brought down from the hilly regions to be processed for distribution.
                        B. Quality Checks
                        Before exporting, the asafoetida goes through rigorous quality checks to ensure that it meets the required standards for consumption.
                        C. Export-Ready Packaging
                        The precious spice is packaged in a way that preserves its quality during transit, ensuring it reaches its destination in prime condition.
                    </p>
                </div> */}
            {/*</div>
            {/*<div className="grid grid-cols-4 gap-8 py-[4%] px-[3%] max-sm:grid-cols-2">
                {
                    data.map((d) =>(
                        <div>
                            <img src={d.image} alt="hing Gallery" className="w-full" />
                        </div>
                    ))
                }
            </div> */}
            {/* <div className="pb-[4%] px-[3%]">
                <Player autoPlay startTime={3} width={200} height={340} >
                    <source src="./images/demoVedio.mp4" />
                </Player>
            </div> */}
            {/* <div className="grid grid-cols-4 gap-8 py-[4%] px-[3%] max-sm:grid-cols-2">
                {
                    data.map((d) =>(
                        <div>
                            <img src={d.image} alt="hing Gallery" className="w-full" />
                        </div>
                    ))
                }
            </div> */}
            
            {/*<div className="flex-col px-[3%] py-[4%]"></div>
             <h1 AFGANI className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">AFGANI HYBRID NUKRA</h1>*/}
                {/*<div className="flex  gap-6 mb-6">
                
                    <div className="w-3/4 max-sm:w-1/2">
                        <img src="./images/rock1.png" alt="gallerydummy" className="w-full h-[320px] max-sm:h-[200px]" />
                    </div>
                    <div className="w-1/4 max-sm:w-1/2">
                        <img src="./images/rock1.png" alt="gallerydummy" className="w-full h-[320px] max-sm:h-[200px]" />
                    </div>
                </div>*/}
                {/*<div className="flex gap-6 mb-6">
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/rock2.png" alt="gallerydummy" className="w-full h-[320px]" />
                    </div>
                    <div className="w-1/2 max-sm:w-full">
                        <img src="./images/rock2.png" alt="gallerydummy" className="w-full h-[320px] max-sm:h-[200px]" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/rock2.png" alt="gallerydummy" className="w-full h-[320px]" />
                    </div>
                </div>*/}
                {/*<div className="flex gap-6 mb-6">
                    <div className="w-1/2">
                        <img src="./images/rock3.png" alt="gallerydummy" className="w-full h-[320px] max-sm:h-[200px]" />
                    </div>
                    <div className="w-1/2">
                        <img src="./images/rock3.png" alt="gallerydummy" className="w-full h-[320px] max-sm:h-[200px]" />
                    </div>
                </div>*/}
              
            {/*<div className="flex-col px-[3%] py-[4%]">8/}
                    {/*.................................<h1 AFGANI className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">AFGANI HYBRID NUKRA</h1>  
                <div className="flex items-center justify-center gap-10 mb-10">
                    <div className="w-1/4 max-sm:hidden flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center">
                     
                       <video controls autostart autoplay src={Afgani2} type="video/mp4" className="w-4/5 mt-6 rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-2" data-aos="zoom-in" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image1.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image2.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                </div>
                <div className="flex items-center justify-center  gap-10 mb-10">
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image3.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                    <div className="w-1/4 max-sm:w-full">
                        <img src="./images/image4.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image5.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                </div>
                <div className="bg-red text-white px-[6%]" >
                    <div className="flex pb-[4%] w-[100%] max-sm:flex-col max-sm:pb-[10%]">
                        <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center"  >
                    
                        <video controls autostart autoplay src={Afgani} type="video/mp4" className="w-2/5 mt-6 shadow-[20px_10px_0px_0px_rgba(300,300,80)] rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-6" data-aos="zoom-in" />
                        </div>
                        <div className="w-1/2  flex flex-col  max-sm:w-full" >
                           
                            <p className="text-2xl mb-6 text-right max-sm:text-base max-sm:text-center" data-aos="flip-up">This is a video of Afghan Farmers bringing there produce for sale to the local markets,<br></br> down from the mountain
                             It’s thoroughly checked on standards of cleanliness, Density, thickness and accordingly sorted as per the quality grade and further in packed in Export ready packs for further export to India
                            </p>
                        </div>
                    </div>
            </div>........................................*/}
            <div className="flex-col px-[3%] py-[4%]">
                <h1 AFGANI className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">ASFOETIDA PLANT GROWTH</h1>
                    <div className="flex  max-sm:flex-col-reverse  items-center justify-center gap-10 mb-0">
                      <div className="w-1/4 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center rounded overflow-hidden shadow-xl">
                 
                        <img src="./images/plant1.jpeg" alt="spices" className="w-full" data-aos="zoom-in" />
                        <div className="px-6 py-0">
                            <div className="text-white text-xl  max-sm:text-base max-sm:pr-0 max-sm:text-center" data-aos="flip-up">
                            A fully frown asafoetida plant, before the harvest, during the spring season, just before the harvest begins
                            </div>
                        </div>
                    </div>
                    <div className="w-1/4 rounded flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center overflow-hidden shadow-xl">
                 
                         <img src="./images/plant2.jpeg" alt="spices" className="w-full" data-aos="zoom-in" />
                        <div className="px-6 py-0">
                            <div className="text-white text-xl  max-sm:text-base max-sm:pr-0 max-sm:text-center">
                            Hing Liquid Coming out from asafoetida, ready for collection
                            </div>
                        </div>
                    </div>
                    <div className="w-1/4 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center rounded overflow-hidden shadow-xl">
                 
                         <img src="./images/plant3.jpeg" alt="spices" className="w-full" data-aos="zoom-in" />
                        <div className="px-6 py-0">
                            <div className=" text-white text-xl  max-sm:text-base max-sm:pr-0 max-sm:text-center">
                              Hing Liquid Is coming out of the plant. Farmer is ready to extract it out, with the help of a knife
                            </div>
                        </div>
                    </div>
                    <div className="w-1/4 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center rounded overflow-hidden shadow-xl">
                 
                         <img src="./images/plant4.jpeg" alt="spices" className="w-full" data-aos="zoom-in" />
                        <div className="px-6 py-0">
                            <div className=" text-white text-xl  max-sm:text-base max-sm:pr-0 max-sm:text-center">
                            Naturally dried asafoetida, ready for extraction from the root of Asafoetida plant.
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="flex-col px-[3%] ">
                    <div className="flex items-center  w-[35%] max-sm:w-[100%] max-sm:px-[25%] max-sm:justify-center">
                       <img src="./images/afgan.png" alt="gallerydummy" className="w-24 h-20" /> </div>
                       <div className="flex flex-col max-sm:text-center  w-[65%] max-sm:w-[100%] ">
                                    <h2 className="capitalize text-white text-2xl font-bold ">afghanistan</h2>
                                    
                                    
                      </div>
                     <h1 AFGANI className="uppercase text-[40px] text-yellow  text-left  leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">AFGANI HYBRID NUKRA</h1>
                    
                    <div className="flex  max-sm:flex-col-reverse  items-center justify-center gap-10 mb-10">
                        <div className="w-1/4  flex flex-col   max-sm:w-full max-sm:items-center">
                     {/*<img src="./images/image0.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />*/}
                       <video controls autoPlay muted src={Afgani} type="video/mp4" className="w-5/5 mt-6 rounded-xl max-sm:w-[95%] max-sm:m-auto max-sm:mb-0" data-aos="zoom-in" />
                        </div>
                    {/*<div className="w-1/4 max-sm:hidden">
                        <img src="./images/image1.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" data-aos="zoom-in" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image2.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" data-aos="zoom-in"/>
                    </div>*/}
                        <div className=" w-3/4 h-[100%] max-sm:w-[100%] max-sm:pl-[0%] max-sm:pr-[0%]">
                         <Slider {...settings} className="max-sm:slidesToShow-2">
                    {
                              data.map((d) => (
                            <div className="h-100 relative pb-4 max-sm:h-50" data-aos="fade-up">
                                <div className=" h-80 mt-10 transition duration-150 ease-out hover:ease-in hover:-translate-y-6 cursor-grab max-sm:h-80 ">
                                <img src={d.img} alt="rocks" className="rounded-xl h-[100%] max-sm:w-[100%] " />
                                    <div className="absolute w-[100%] h-[100%]  top-0 left-0 opacity-0 transition duration-150 ease-out hover:opacity-100 hover:ease-in">
                                    <p className="text-xl bg-yellow text-black absolute lg:w-[90%] w-[100%] top-[100%] text-center capitalize max-sm:text-base rounded-xl">{d.value}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    </Slider>
                    
                   </div>
                
                {/*$$$$$$$$$$<div className="flex items-center justify-center  gap-10 mb-10">
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image3.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" data-aos="zoom-in"/>
                    </div>
                    <div className="w-1/4 max-sm:w-full">
                        <img src="./images/image4.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" data-aos="zoom-in"/>
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image5.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" data-aos="zoom-in"/>
                    </div>
                </div>$$$$$$$$*/}
                </div>
                <div className="bg-red text-white px-[3%]" >
                    < div className="flex pb-[3%] w-[100%] max-sm:flex-col max-sm:pb-[0%]">
                        
                           
                        
                        <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-[100%] max-sm:items-center"  >
                    
                               <video controls autoPlay muted src={Afgani2} type="video/mp4" className="w-2/3 mt-4 rounded-xl max-sm:w-[100%] max-sm:m-auto max-sm:mb-0" data-aos="zoom-in" />
                            </div>
                            <div className="w-1/2  flex flex-col  max-sm:w-full max-sm:items-center" >
                                 {/*<h1 className="uppercase text-[40px] text-right text-yellow leading-tight mb-3  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">EXTRACTING THE ASAFOETIDA</h1>*/}
                                <p className="text-4xl text-end  py-[6%] max-sm:text-base max-sm:pr-0 max-sm:text-center" data-aos="flip-up">This is a video of Afghan Farmers bringing there produce for sale to the local markets, down from the mountain
                                 It’s thoroughly checked on standards of cleanliness, Density, thickness and accordingly sorted as per the quality grade and further in packed in Export ready packs for further export to India
                                </p>
                             </div>
                             
                        </div>
                    </div>
                
                {/*<div className="bg-red text-white px-[6%]" >
                    <div className="flex  max-sm:flex-col-reverse py-[4%] max-sm:py-[10%] " >
                        <div className="w-1/2  flex flex-col  max-sm:w-full" data-aos="flip-up" >
                             <h1 className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">IRAN RAS</h1>
                           <p className="text-2xl  max-sm:text-base max-sm:pr-0 max-sm:text-center" data-aos="flip-up" > Iranian Ras, a fragrant variety found in northern Iran, known for its sweet aroma and high quality, also recognized as Hadda or Irani Ras. These products originate from a large farm, packaged in 5-6 kg bottles that undergo meticulous inspection, sorting, and grading before being prepared for export. Once sorted, they're packaged into export-ready bundles destined for shipping to India.


                           </p>
                        </div>
                    </div>
                    <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center">
                        
                        <video controls autostart autoplay src={video} type="video/mp4" className="w-4/5 mt-6  rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-6" data-aos="zoom-in" />
                        
                    </div>
                </div>
                <div className="flex-col px-[3%] py-[4%]">
                    <div className="flex gap-6 mb-6">
                        <div className="w-1/3">
                            <img src="./images/iran1.jpeg" alt="gallerydummy" className="w-full  rounded-xl h-[320px] max-sm:h-[200px]" />
                        </div>
                        <div className="w-1/2">
                            <img src="./images/iran2.jpeg" alt="gallerydummy" className="w-full  rounded-xl h-[320px] max-sm:h-[200px]" />
                        </div>
                    </div>
        </div>*/}
                
                
                
                {/*<div className="flex gap-6 mb-6">
                    <div className="w-1/2">
                        <img src="./images/rock3.png" alt="gallerydummy" className="w-full h-[320px] max-sm:h-[200px]" />
                    </div>
                    <div className="w-1/2">
                        <img src="./images/rock3.png" alt="gallerydummy" className="w-full h-[320px] max-sm:h-[200px]" />
                    </div>
                </div>*/}
                <div className="bg-red text-white px-[6%] " >
                  <div className="flex  max-sm:flex-col py-[2%] max-sm:py-[0%] " >
                    <div className="w-1/2  flex flex-col  max-sm:w-full" data-aos="flip-up" >
                    <div className="flex items-center  w-[35%] max-sm:w-[100%] max-sm:px-[25%] max-sm:justify-center">
                       <img src="./images/iran.png" alt="gallerydummy" className="w-24 h-20" /> </div>
                       <div className="flex flex-col  max-sm:text-center  w-[65%] max-sm:w-[100%]">
                                    <h2 className="capitalize text-white text-2xl font-bold">iran</h2>
                                    
                                    
                      </div>
                        <h1 className="uppercase text-[40px] text-yellow  text-left mb-4 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">IRAN RAS</h1>
                        <p className="text-2xl max-sm:text-base max-sm:pr-0 max-sm:text-center" data-aos="flip-up" > Iranian Ras, a fragrant variety found in northern Iran, known for its sweet aroma and high quality, also recognized as Hadda or Irani Ras. These products originate from a large farm, packaged in 5-6 kg bottles that undergo meticulous inspection, sorting, and grading before being prepared for export. Once sorted, they're packaged into export-ready bundles destined for shipping to India.


                        </p>
                    </div>
                    <div className="w-1/2  flex flex-col items-center justify-end max-sm:w-full max-sm:items-center max-sm:py-[4%] ">
                        
                        <video controls autoPlay muted src={video} type="video/mp4" className="w-5/6 mt-6  rounded-xl max-sm:w-[100%] max-sm:m-auto max-sm:mb-6" data-aos="zoom-in" />
                        
                    </div>
                </div>
                <div className=" flex   px-[0%] ">
                    <div className=" md:flex  gap-6 mb-6  ">
                        <div className=" ">
                            <img src="./images/iran1.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px] max-sm:h-[200px] max-sm:w-[320px] mb-6 " data-aos="zoom-in"/>
                        </div>
                        <div className=" ">
                            <img src="./images/iran2.jpeg" alt="gallerydummy" className="w-full  rounded-xl h-[320px] max-sm:h-[200px] max-sm:w-[320px] " data-aos="zoom-in"/>
                        </div>
                    </div>
                </div>
                
                {/*...........................<div className="bg-red text-white px-[6%]" >
                    <div className="flex  max-sm:flex-col-reverse py-[4%] max-sm:py-[10%] " >
                        <div className="w-1/2  flex flex-col  max-sm:w-full" data-aos="flip-up" >
                          <h1 className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">KAZAKHSTAN</h1>
                          <p className="text-2xl  max-sm:text-base max-sm:pr-0 max-sm:text-center" data-aos="flip-up" >Kazakhstani farmers packing goods directly into export-ready bundles right at their farms,<br></br> streamlining the packaging process for shipment. </p>
                        </div>
                        <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center">
                          <img src="./images/kaza1.gif" alt="spices" className="w-2/5 shadow-[20px_10px_0px_0px_rgba(300,300,80)] rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-6" data-aos="zoom-in" />
                       </div>
                    </div>
                </div>
                <div className="flex pb-[4%] w-[100%] max-sm:flex-col max-sm:pb-[10%]">
                    <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center"  >
                    
                        <img src="./images/kaza2.gif" alt="spices" className="w-[40%] shadow-[20px_10px_0px_0px_rgba(300,300,80)] rounded-xl  max-sm:mb-6" data-aos="zoom-in" />
                    </div>
                     <div className="w-1/2  flex flex-col  max-sm:w-full" >
                        
                        <p className="text-2xl mb-6 text-right max-sm:text-base max-sm:text-center" data-aos="flip-up">This is a video of Kazakhi PIR NAKSIR lot received in IndiA</p>
                    </div>
            </div>............................*/}
                
                <div className="flex-col px-[0%] ">
                <div className="flex items-center  w-[35%] max-sm:w-[100%] max-sm:px-[25%] max-sm:justify-center">
                       <img src="./images/kazak.png" alt="gallerydummy" className="w-24 h-20" /> </div>
                       <div className="flex flex-col  max-sm:text-center  w-[65%] max-sm:w-[100%]">
                                    <h2 className="capitalize text-white text-2xl font-bold">kazakhstan</h2>
                        </div>

                <h1 AFGANI className="uppercase text-[40px] text-yellow  text-left  leading-tight  max-sm:px-[0] max-sm:text-2xl max-sm:text-center" data-aos="flip-up">PIR NAKSIR</h1>
                   <div className="flex max-sm:flex-col-reverse py-[2%] max-sm:py-[6%]  justify-left gap-10 mb-10 object-top">
                    <div className="w-1/4 flex flex-col   max-sm:w-full max-sm:items-center rounded overflow-hidden shadow-xl ">
                 
                        <img src="./images/kaza1.gif" alt="spices" className="w-full " data-aos="zoom-in" />
                        <div className="px-2 ">
                            <div className="text-xl  max-sm:text-base max-sm:pr-0 max-sm:text-center">
                            Kazakhstani farmers packing goods directly into export-ready bundles right at their farms, streamlining the packaging process for shipment.
                            </div>
                        </div>
                    </div>
                    <div className="w-1/4 flex flex-col  max-sm:w-full max-sm:items-center rounded overflow-hidden shadow-xl">
                 
                         <img src="./images/kaza2.gif" alt="spices" className="w-full max-sm:w-[100%]" data-aos="zoom-in" />
                        <div className="px-2 ">
                            <div className=" text-xl  max-sm:text-base max-sm:pr-0 max-sm:text-center">
                            This is a video of  Kazakhi PIR NAKSIR lot received in IndiA
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
             </div>
                
                     {/*<h1 AFGANI className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">AFGANI HYBRID NUKRA</h1>
                    
                     <div className="flex items-center justify-center gap-10 mb-10">
                    <div className="w-1/4 max-sm:hidden flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center">
                     
                       <video controls autostart autoplay src={Afgani2} type="video/mp4" className="w-5/5 mt-6 rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-2" data-aos="zoom-in" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image1.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image2.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                    
                </div>*/}
                {/*<div className="flex items-center justify-center  gap-10 mb-10">
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image3.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                    <div className="w-1/4 max-sm:w-full">
                        <img src="./images/image4.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
                    <div className="w-1/4 max-sm:hidden">
                        <img src="./images/image5.jpeg" alt="gallerydummy" className="w-full rounded-xl h-[320px]" />
                    </div>
        </div>*/}
                {/*<div className="bg-red text-white px-[6%]" >
                    <div className="flex pb-[4%] w-[100%] max-sm:flex-col max-sm:pb-[10%]">
                        <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center"  >
                    
                        <video controls autostart autoplay src={Afgani} type="video/mp4" className="w-3/5 mt-6 rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-6" data-aos="zoom-in" />
                        </div>
                        <div className="w-1/2  flex flex-col  max-sm:w-full" >
                          
                            <p className="text-2xl mb-6 text-right max-sm:text-base max-sm:text-center" data-aos="flip-up">This is a video of Afghan Farmers bringing there produce for sale to the local markets,<br></br> down from the mountain
                             It’s thoroughly checked on standards of cleanliness, Density, thickness and accordingly sorted as per the quality grade and further in packed in Export ready packs for further export to India
                            </p>
                        </div>
                    </div>
        </div>*/}
                {/*</div>*/}
                {/*<div className="bg-red text-white px-[6%]" >
                    <div className="flex  max-sm:flex-col-reverse py-[4%] max-sm:py-[10%] " >
                        <div className="w-1/2  flex flex-col  max-sm:w-full" data-aos="flip-up" >
                             <h1 className="uppercase text-[40px] text-yellow  text-left mb-8 leading-tight  max-sm:px-0 max-sm:text-2xl max-sm:text-center" data-aos="flip-up">IRAN RAS</h1>
                           <p className="text-2xl  max-sm:text-base max-sm:pr-0 max-sm:text-center" data-aos="flip-up" > Iranian Ras, a fragrant variety found in northern Iran, known for its sweet aroma and high quality, also recognized as Hadda or Irani Ras. These products originate from a large farm, packaged in 5-6 kg bottles that undergo meticulous inspection, sorting, and grading before being prepared for export. Once sorted, they're packaged into export-ready bundles destined for shipping to India.


                           </p>
                        </div>
                    </div>
                    <div className="w-1/2 flex flex-col items-center justify-center  max-sm:w-full max-sm:items-center">
                        
                        <video controls autostart autoplay src={video} type="video/mp4" className="w-4/5 mt-6  rounded-xl max-sm:w-[40%] max-sm:m-auto max-sm:mb-6" data-aos="zoom-in" />
                        
                    </div>
                </div>
                <div className="flex-col px-[3%] py-[4%]">
                    <div className="flex gap-6 mb-6">
                        <div className="w-1/3">
                            <img src="./images/iran1.jpeg" alt="gallerydummy" className="w-full  rounded-xl h-[320px] max-sm:h-[200px]" />
                        </div>
                        <div className="w-1/2">
                            <img src="./images/iran2.jpeg" alt="gallerydummy" className="w-full  rounded-xl h-[320px] max-sm:h-[200px]" />
                        </div>
                    </div>
        </div>*/}
                
                
            
        </div>
        
        
        

        </div>    
            <Section9/>
            <Section10/>
        </div>
        
    )
    
}


export default Gallery;