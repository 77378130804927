import Header from "./Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Section9 from "./Section9";
import Section10 from "./Section10";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./ScrollToTop";

const Brand = () => {
  useEffect(() => {
    AOS.init({
      duration: 2500,
      once: true,
    });
    AOS.refresh();
  }, []);

  const data = [
    {
      value: "brands",
      img: "/images/photo1.jpg",
    },
    // {
    //     value : 'brands' ,
    //     img : './images/brandf2.avif',
    // },
    {
      value: "brands",
      img: "/images/photo2.webp",
    },
    // {
    //     value : 'brands' ,
    //     img : './images/brandf2.avif',
    // },
    {
      value: "brands",
      img: "/images/photo3.jpg",
    },
    {
      value: "brands",
      img: "/images/photo4.webp",
    },
    {
      value: "brands",
      img: "/images/photo5.webp",
    },
    {
      value: "brands",
      img: "/images/photo6.webp",
    },
    {
      value: "brands",
      img: "/images/photo7.webp",
    },
    {
      value: "brands",
      img: "./images/photo2.webp",
    },
    // {
    //     value : 'brands' ,
    //     img : './images/brandf2.avif',
    // },
  ];

  var settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnFocus: false,
    pauseOnHover: true,
    cssEase: "linear",
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div className="bg-red browse brand">
      <ScrollToTop />
      <Header />
      <div className="relative w-full">
        <img
          src="./images/brandss.png"
          alt="hing"
          className="w-full h-[400px] max-sm:h-[200px]"
        />
        <h1 className="text-red font-bold absolute bg-white_blur top-[55%] w-full text-center uppercase max-sm:text-3xl max-sm:top-[57%]">
          our brands
        </h1>
      </div>
      <div className="flex text-white pl-[4%] pr-[4%] py-[4%] lg:py-[2%] max-sm:flex-col">
        <div className="flex flex-col w-1/2 max-sm:w-full max-sm:mb-6">
          <div className="flex justify-center max-sm:mb-3" data-aos="fade-up">
            <img src="./images/brand1.png" alt="bottle" className="w-5/5" />
          </div>
          <div className=" w-3/5 pt-6  m-auto pb-[3%]  max-sm:w-[100%] max-sm:pl-[10%] max-sm:pr-[12%] max-sm:pb-[10%]">
            <Slider {...settings} className="max-sm:slidesToShow-2">
              {data.map((d) => (
                <div className="  pl-4 h-30 ">
                  <div className="cursor-grab flex items-center justify-center">
                    <img
                      src={d.img}
                      alt="rocks"
                      className=" h-full bg-white border-solid border-4 border-yellow  "
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/*<div className="flex justify-center">
                        <img src="./images/brand1.png" alt="bottle" className="w-1/5 h-full bg-white border-solid border-4 border-yellow mr-3" data-aos="flip-up"  />
                        <img src="./images/brand1.png" alt="bottle" className="w-1/5 h-full bg-white border-solid border-4 border-yellow" data-aos="flip-up"  />
    </div>*/}
        </div>
        <div className="flex flex-col w-5/5 py-10 max-sm:w-full max-sm:text-center">
          <h3 className="mb-6 max-sm:text-xl" data-aos="fade-up">
            URBAN PLATTER HING POWDER, 100G [COMPOUNDED ASAFOETIDA | SPICE |
            SAVOURY & UMAMI FLAVOUR] (ADD TO DALS, VEGETABLES, GRAVIES)
          </h3>
          <div
            className="flex items-center mb-6 max-sm:justify-center "
            data-aos="fade-up"
          >
            <img
              src="./images/brand3.png"
              alt="vegetarian"
              className="w-[3%] mr-3"
            />
            <span className="text-base">This is a Vegetarian product.</span>
          </div>

          <h2
            className="text-yellow max-sm:text-2xl max-sm:mb-3"
            data-aos="fade-up"
          >
            About this item
          </h2>
          <div className="flex w-1/2 max-sm:w-full" data-aos="fade-up">
            <div className="w-1/2 pr-3">
              <p>
                100% Raw Hing Direct from Farm Hand Pounded, Hand Cleaned Brand-
                Persian Foods
              </p>
            </div>

            <div className="w-1/2 ">
              <p>
                Variety- Hing Net Quantity- 10 gram Diet Type- Vegetarian
                Speciality- Organic
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-white flex flex-col  items-center ">
        <h1 className="flex justify-center text-3xl w-fit border-solid border-b-4 border-yellow px-10 pb-3 m-auto max-sm:text-2xl max-sm:mt-4 ">
        BUY PERSIAN ASAFOETIDA ON
        </h1>
        <div className="md:flex md:w-full mt-14 mb-10 gap-10  justify-center items-center w-[90%] text-center  ">
          <div className="rounded overflow-hidden shadow-lg  md:w-1/6  my-4 border-solid border-4 border-yellow">
            <img
              className="w-full bg-white py-16"
              src="./images/Amazon_logo.svg.png"
              alt="Sunset in the mountains"
            />
            <p className="font-bold text-xl  px-6 py-2">
              Viwe details on Amazon
            </p>
            <a href="https://www.amazon.in/Persian-Foods-Aromatic-Organic-Asafoetida/dp/B01LBFAMO6" target="_blank" rel="noreferrer"><button className="    border-2 border-solid border-yellow text-center capitalize px-8  lg:mb-4 mb-6 hover:bg-yellow hover:text-black rounded-xl cursor-pointer ">view More</button></a>
          </div>
          <div className="rounded overflow-hidden shadow-lg md:w-1/6  my-4 border-solid border-4 border-yellow">
            <img
              className="w-full bg-white py-16"
              src="./images/bank.png"
              alt="Sunset in the mountains"
            />
            <div className="px-6 py-2">
              <p className="font-bold text-xl mb-2">View on Mordern Bazar</p>
            </div>
            
              <button className="  border-2 border-solid border-yellow text-center capitalize px-8 lg:mb-4 mb-6 hover:bg-yellow hover:text-black rounded-xl cursor-pointer ">view More</button>
            
          </div>
          <div className="rounded overflow-hidden shadow-lg  my-4 md:w-1/6 border-solid border-4 border-yellow">
            <img
              className="w-full bg-white py-3"
              src="./images/birla.png"
              alt="Sunset in the mountains"
            />
            <div className="px-6 py-2">
              <p className="font-bold text-xl mb-2">View on Fashion and Retil</p>
            </div>
            <div className="px-6  pb-2 text-center">
              <button className="   border-2 border-solid border-yellow text-center capitalize px-8 lg:mb-2  hover:bg-yellow hover:text-black rounded-xl cursor-pointer">view More</button>
            </div>
          </div>
          <div className=" rounded overflow-hidden shadow-lg  my-4 md:w-1/6 border-solid border-4 border-yellow">
            <img
              className="w-full bg-white  "
              src="./images/jio-mart.png"
              alt="Sunset in the mountains"
            />
            <div className="px-6 py-2">
              <p className="font-bold text-xl mb-2">View details on Jio Mart</p>
            </div>
            <div className="px-6  pb-2 text-center">
              <a href="https://www.jiomart.com/p/groceries/persian-foods-raw-hing-10-g/590145714" target="_blank" rel="noreferrer"><button className="  border-2 border-solid border-yellow text-center capitalize px-8 lg:mb-4 mb-6 hover:bg-yellow hover:text-black rounded-xl cursor-pointer">view More</button></a>
            </div>
          </div>
        </div>
        <div className="md:flex md:w-full mb-14 gap-10  justify-center w-[90%]  text-center">
          <div className=" rounded overflow-hidden shadow-lg  my-4 md:w-1/6 border-solid border-4 border-yellow">
            <img
              className="w-full bg-white "
              src="./images/logobrand.png.png"
              alt="Sunset in the mountains"
            />
            <p className="font-bold text-xl  px-6 py-2">
              View details on Rajmandir
            </p>
            <button className="  border-2 border-solid border-yellow text-center capitalize px-8 mb-2  hover:bg-yellow hover:text-black rounded-xl cursor-pointer">view More</button>
          </div>
          <div className=" rounded overflow-hidden shadow-lg  my-4 md:w-1/6 border-solid border-4 border-yellow">
            <img
              className="w-full bg-white py-16"
              src="./images/liveorganic.png"
              alt="Sunset in the mountains"
            />
            <p className="font-bold text-xl  px-6 py-4">
              View details on LiverOrganic
            </p>
            <a href="https://liveorganic.co.in/products/persian-hing-10-g" target="_blank" rel="noreferrer"><button className="  border-2 border-solid border-yellow text-center capitalize px-8 md:mb-2 mb-6 hover:bg-yellow hover:text-black rounded-xl cursor-pointer">view More</button></a>
          </div>
          <div className=" rounded overflow-hidden shadow-lg  my-4 md:w-1/6 border-solid border-4 border-yellow ">
            <img
              className="w-full bg-white py-14"
              src="./images/SPENCERS.png"
              alt="Sunset in the mountains"
            />
            <p className="font-bold text-xl mb-2 px-6 py-2">
              View details on Spencers
            </p>
            <a href="https://mcprod.spencers.in/spices-bill-offer/persian-hing-granules-10g-1305996.html" target="_blank" rel="noreferrer"><button className="  border-2 border-solid border-yellow text-center capitalize px-8 md:mb-2 mb-6 hover:bg-yellow hover:text-black rounded-xl cursor-pointer">view More</button></a>
          </div>
          <div className=" rounded overflow-hidden shadow-lg  my-4 md:w-1/6 border-solid border-4 border-yellow ">
            <img
              className="w-full bg-white py-4"
              src="./images/indiamart.png"
              alt="Sunset in the mountains"
            />
            <p className="font-bold text-xl mb-2 px-6 py-2">
              View details on Indiamart
            </p>
            <a href="https://mcprod.spencers.in/spices-bill-offer/persian-hing-granules-10g-1305996.html" target="_blank" rel="noreferrer"><button className="  border-2 border-solid border-yellow text-center capitalize px-8 md:mb-4 mb-6 hover:bg-yellow hover:text-black rounded-xl cursor-pointer">view More</button></a>
          </div>
        </div>
      </div>
      <Section9 />
      <Section10 />
    </div>
  );
};

export default Brand;
