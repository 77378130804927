import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const Section9 = () => {
    useEffect(() => {
        AOS.init({ 
            duration: 2500,
            once:true,
        });
        AOS.refresh();
    }, []);

    const navigate =useNavigate();

    const handleChange = (e) => {
        // console.log(e.target.dataset.path);
        navigate(e.target.dataset.path);
    }

    return (
        <div className="bg-grey pl-[12%] pr-[8%] pt-[6%] pb-[3%] flex border-b max-sm:flex max-sm:px-4 max-sm:flex-col" id="s9">
            <div className="flex flex-col w-[50%] max-sm:w-[100%] max-sm:items-center max-sm:text-center">
                <img src="./images/raghu2.png" alt="logo" className="pb-3 w-[40%] h-[50%]" data-aos="flip-up" />
                <p className="pb-5 border-b " data-aos="fade-up">SRI RAGHUNATH JI OVERSEAS: Excelling in Hing and hing imports from global origins since more than 50 years now , renowned for its commitment to quality.</p>
                <div className="flex pt-3" data-aos="fade-up">
                    <a href="https://www.facebook.com/" >
                    <img src="./images/facebook.svg" alt="media" className="pr-2 " /> 
                    </a>
                    <a href="https://twitter.com/" >
                    <img src="./images/twitter.svg" alt="media" className="pr-2" />
                    </a>
                    <a href="https://www.youtube.com/" >
                    <img src="./images/youtube.svg" alt="media" className="pr-2" />
                    </a>
                    <a href="https://www.instagram.com/">
                    <img src="./images/insta.svg" alt="media" className="pr-2" />
                    </a>
                </div>
            </div>
            <div className="flex flex-col w-[20%] pl-16 max-sm:w-[100%] max-sm:px-0 max-sm:text-center max-sm:pt-6" data-aos="fade-up">
                <h3 className="text-red capitalize max-sm:text-2xl ">useful links</h3>
                <ul className="max-sm:text-xl">
                    <li className="cursor-pointer hover:text-red hover:underline" onClick={handleChange} data-path='/'>Home</li>
                    <li className="cursor-pointer hover:text-red hover:underline" onClick={handleChange} data-path='/About'>About Us</li>
                    <li className="cursor-pointer hover:text-red hover:underline" onClick={handleChange} data-path='/Process'>Process</li>
                    <li className="cursor-pointer hover:text-red hover:underline" onClick={handleChange} data-path='/Gallery'>Gallery</li>
                    <li className="cursor-pointer hover:text-red hover:underline" onClick={handleChange} data-path='/Brand'>Our Brands</li>
                    <li className="cursor-pointer hover:text-red hover:underline" onClick={handleChange} data-path='/Contact'>Contact Us</li>
                </ul>
            </div>
            <div className="flex flex-col w-[40%] pl-16 max-sm:w-[100%] max-sm:px-0 max-sm:text-center max-sm:pt-6" data-aos="fade-up">
                <h3 className="text-red capitalize max-sm:text-2xl">Contact info</h3>
                <ul className="max-sm:text-xl">
                    {/* <li>Phone</li> */}
                    <p ><span>Phone : </span><a href="tel:+919810128609" className="curser-pointer hover:text-red hover:underline">+91 9910128609, +91 9810128609 </a></p>
                     {/* <li>Email</li> */}
                    <p><span>Email : </span><a href="mailto:sriraghunathjioverseas@gmail.com" className="curser-pointer hover:text-red hover:underline">sriraghunathjioverseas@gmail.com</a></p>
                    {/* <li>Address</li> */}
                    <p><span>Address : </span>5163, Kohlapur Road, Kamla Nagar, Delhi, 110007</p>
                </ul>
            </div>

        </div>
    )
}

export default Section9 ;