import React from 'react';
import Header from "./Header";
import ScrollToTop from "./ScrollToTop";
import Section9 from "./Section9";
import Section10 from "./Section10";

const Terms = () => {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <div className="relative w-full overflow-hidden">
        <img src="./images/contact_back.jpg" alt="hing" className="w-full h-[400px]" />
        <h1 className="text-red font-bold absolute bg-white_blur top-[55%] w-full text-center uppercase max-sm:text-3xl max-sm:top-[57%]">Terms & Conditions</h1>
      </div>
      <div className="bg-red p-8 text-white">
        <p className="text-xl py-4 max-sm:text-base">
          Welcome to SRI RAGHUNATH JI OVERSEAS, India's Top Leading Provider In The Hing Import Business. These terms and conditions ("Terms") govern your use of our services and website. By accessing or using our services, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using our services.
        </p>

        <h2 className="text-2xl mt-4">Use of Services</h2>

        <ul className="list-disc pl-8">
          <li>You must be of legal age to engage with our services and make purchases.</li>
          <li>You agree to provide accurate and complete information when placing orders or using our website.</li>
          <li>You are responsible for maintaining the confidentiality of your account information and password.</li>
        </ul>

        <h2 className="text-2xl mt-4">Product Information</h2>

        <p className="text-xl py-4 max-sm:text-base">
          We strive to accurately represent our products; however, we do not guarantee the accuracy, completeness, or reliability of product descriptions. The images of products on our website are for illustrative purposes only and may not exactly represent the product you receive.
        </p>

        <h2 className="text-2xl mt-4">Pricing and Payments</h2>

        <ul className="list-disc pl-8">
          <li>Prices for our products are subject to change without notice.</li>
          <li>All payments for orders must be made using the provided payment methods.</li>
          <li>We reserve the right to refuse or cancel orders in cases of pricing errors or any other reasons deemed necessary.</li>
        </ul>

        <h2 className="text-2xl mt-4">Shipping and Delivery</h2>

        <p className="text-xl py-4 max-sm:text-base">
          We aim to deliver products within the specified timeframes; however, delivery times may vary due to factors beyond our control. Any delays or issues in delivery will be communicated to you promptly.
        </p>

        <h2 className="text-2xl mt-4">Intellectual Property</h2>

        <p className="text-xl py-4 max-sm:text-base">
          All content on our website, including text, graphics, logos, and images, is the property of SRI RAGHUNATH JI OVERSEAS and is protected by intellectual property laws.
        </p>

        <h2 className="text-2xl mt-4">Limitation of Liability</h2>

        <p className="text-xl py-4 max-sm:text-base">
          We shall not be liable for any indirect, incidental, special, or consequential damages arising out of or related to the use of our services or products.
        </p>

        <h2 className="text-2xl mt-4">Governing Law</h2>

        <p className="text-xl py-4 max-sm:text-base">
          These Terms are governed by the laws of [Your Country/Region]. Any disputes arising from these Terms shall be resolved through arbitration or in a court of law in Delhi Court.
        </p>

        <h2 className="text-2xl mt-4">Changes to Terms</h2>

        <p className="text-xl py-4 max-sm:text-base">
          We reserve the right to modify or update these Terms at any time without prior notice. It is your responsibility to review these Terms periodically.
        </p>

        <h2 className="text-2xl mt-4">Contact Us</h2>

        <p className="text-xl py-4 max-sm:text-base">
          If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:contact@sriraghunathjioverseas.com">contact@sriraghunathjioverseas.com</a>.
            <br></br>
          By using our services or interacting with our website, you agree to abide by these Terms and Conditions.
        </p>
      </div>
      <Section9 />
      <Section10 />
    </div>
  )
}

export default Terms;
